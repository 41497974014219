<template>
  <div
    class="loginWrapper"
    @keydown.enter="handleLogin"
  >
    <img
      src="../assets/logo.png"
      style="height: 50px; position: fixed; left: 50px; top: 30px;"
    >
    <img
      src="../assets/title.png"
      style="height: 90px; position: fixed; left: 50px; top: 110px;"
    >
    <div class="loginContainer">
      <h2 style="color: #FFF; font-size: 18px; font-family: '宋体'">
        管理平台
      </h2>
      <el-form
        ref="loginForm"
        :model="formModel"
        style="width: 100%;"
        :rules="formRules"
      >
        <el-form-item prop="username">
          <el-input
            v-model="formModel.username"
            :placeholder="$t('placeholder.username')"
            size="medium"
          >
            <i
              slot="prefix"
              class="iconfont icon-username"
              style="line-height: 36px;"
            />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formModel.password"
            :placeholder="$t('placeholder.password')"
            size="medium"
            show-password
          >
            <i
              slot="prefix"
              class="iconfont icon-password"
              style="line-height: 36px;"
            />
          </el-input>
        </el-form-item>
        <div v-if="showVerCodeFlag">
          <el-form-item prop="">
            <el-button
              style="width:100%;"
              type="primary"
              size="medium"
              :disabled="sendFlag"
              @click="handleSendVerCode"
            >
              {{ buttonInfo }}
            </el-button>
          </el-form-item>
          <el-form-item prop="verCode">
            <el-input
              v-model="formModel.verCode"
              placeholder="验证码"
              size="medium"
            >
              <i
                slot="prefix"
                class="iconfont icon-password"
                style="line-height: 36px;"
              />
            </el-input>
          </el-form-item>
        </div>
        <!-- <el-form-item> -->
        <el-button
          :loading="buttonLoading"
          style="width: 100%;"
          size="medium"
          type="primary"
          @click="handleLogin"
        >
          登录
        </el-button>
        <!-- <div
          class="forgetPassword"
          @click="forgetPwdVisible=true"
        >
          Forget Password
        </div> -->
        <!-- </el-form-item> -->
      </el-form>
      <p style="color: #FFF; font-family: '宋体'; font-size: 12px; position: absolute; bottom: 0;">
        © 2020 版权所有
      </p>
    </div>
    <!-- <forget-password :show-flag.sync="forgetPwdVisible" />
    <change-password
      :show-flag.sync="showFlag"
      :login-name="formModel.username"
    /> -->
    <change-password
      :show-flag.sync="showFlag"
      :login-name="formModel.username"
    />
  </div>
</template>
<script>
// import forgetPassword from '@/views/system/account/forgetPassword'
import changePassword from '@/views/system/password/changePassword'
const BASEURL = {
  login: '/auth/oauth/token',
  sendVerificationCode: '/system/userExtend/sendVerificationCode'
}
let verCode
export default {
  name: 'Login',
  components: {
    // forgetPassword,
    changePassword
  },
  data () {
    return {
      buttonLoading: false,
      showVerCodeFlag: false,
      showFlag: false,
      sendFlag: false,
      buttonInfo: '发送验证码',
      formModel: {
        username: '',
        password: ''
      },
      formRules: {
        username: { required: true, message: this.$t('validate.notBlank'), trigger: 'blur' },
        password: { required: true, message: this.$t('validate.notBlank'), trigger: 'blur' },
        verCode: { required: true, message: this.$t('validate.notBlank'), trigger: 'blur' }
      },
      forgetPwdVisible: false
    }
  },
  methods: {
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.buttonLoading = true

          let params = `username=${encodeURIComponent(this.formModel.username)}&password=${encodeURIComponent(this.formModel.password)}`
          if (this.showVerCodeFlag) {
            params = params + `&verCode=${this.formModel.verCode}`
          }
          const ot = '&grant_type=password&scope=web&client_id=web&client_secret=web'

          this.$axios
            .post(`${BASEURL.login}?${params}${ot}`)
            .then(resp => {
              const respData = resp
              // 获取 token 并保存至 vuex 中
              const token = respData.access_token
              this.$store.commit('updateAccessToken', token)
              this.$router.replace({ name: 'home' })
            })
            .catch((err) => {
              if (err.response.data && (err.response.data.message.indexOf('验证码已过期,请重新验证') >= 0)) {
                this.showVerCodeFlag = true
              } else if (err.response.data && (err.response.data.message.indexOf('初始化帐户，请更改密码') >= 0)) {
                this.showFlag = true
              }
            })
            .finally(() => {
              this.buttonLoading = false
            })
        }
      })
    },
    handleSendVerCode () {
      localStorage.setItem('login_sendVerCodeTime', this.$moment())
      let count = 60
      this.sendFlag = true
      verCode = setInterval(() => {
        if (count === 0) {
          this.buttonInfo = '重新发送'
          clearInterval(verCode)
          this.sendFlag = false
        } else {
          this.buttonInfo = count + 'S 重新发送'
        }
        count--
      }, 1000)
      this.$axios.post(BASEURL.sendVerificationCode, { loginName: this.formModel.username }).then(resp => {
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .loginWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    background-color: #faaca8;
    // background-image: linear-gradient(19deg, #faaca8 0%, #ddd6f3 100%);
    background-image: url('../assets/login.jpg');
    background-size:100% 100%;

    .loginContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 300px;
      padding: 0 10px;
      box-shadow: 1px 1px 10px 0px #ccc;
      background-color: rgba(38, 43, 45, 0.5);
      .forgetPassword {
        padding: 40px;
        text-align: center;
        color: #cbcbcb;
        font-size: 14px;
        &:hover {
          color: white;
          cursor: pointer;
        }
      }
    }
  }
</style>
